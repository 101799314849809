// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-1-js": () => import("./../../../src/pages/aboutus-1.js" /* webpackChunkName: "component---src-pages-aboutus-1-js" */),
  "component---src-pages-aboutus-2-js": () => import("./../../../src/pages/aboutus-2.js" /* webpackChunkName: "component---src-pages-aboutus-2-js" */),
  "component---src-pages-blog-grid-js": () => import("./../../../src/pages/blog-grid.js" /* webpackChunkName: "component---src-pages-blog-grid-js" */),
  "component---src-pages-blog-list-js": () => import("./../../../src/pages/blog-list.js" /* webpackChunkName: "component---src-pages-blog-list-js" */),
  "component---src-pages-blogdetails-1-js": () => import("./../../../src/pages/blogdetails-1.js" /* webpackChunkName: "component---src-pages-blogdetails-1-js" */),
  "component---src-pages-blogdetails-2-js": () => import("./../../../src/pages/blogdetails-2.js" /* webpackChunkName: "component---src-pages-blogdetails-2-js" */),
  "component---src-pages-contact-1-js": () => import("./../../../src/pages/contact-1.js" /* webpackChunkName: "component---src-pages-contact-1-js" */),
  "component---src-pages-contact-2-js": () => import("./../../../src/pages/contact-2.js" /* webpackChunkName: "component---src-pages-contact-2-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-home-1-js": () => import("./../../../src/pages/home-1.js" /* webpackChunkName: "component---src-pages-home-1-js" */),
  "component---src-pages-home-2-js": () => import("./../../../src/pages/home-2.js" /* webpackChunkName: "component---src-pages-home-2-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-details-js": () => import("./../../../src/pages/portfolio-details.js" /* webpackChunkName: "component---src-pages-portfolio-details-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-services-1-js": () => import("./../../../src/pages/services-1.js" /* webpackChunkName: "component---src-pages-services-1-js" */),
  "component---src-pages-services-2-js": () => import("./../../../src/pages/services-2.js" /* webpackChunkName: "component---src-pages-services-2-js" */)
}

